<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>
    <div>
      <el-button
        class="but"
        type="primary"
        @click="showAdd"
        v-auth="this.per.AGENT_EMPLOYEE_ADD"
        >添加账户</el-button
      >
    </div>
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="站长名称" prop="name"></el-table-column>
      <el-table-column label="手机号码" prop="phone"></el-table-column>
      <el-table-column label="押金" prop="deposited"></el-table-column>
      <el-table-column label="可代付人数" prop="userNum"></el-table-column>
      <el-table-column label="结算套餐金额" prop="amount">
        <template #default="scope">
          <span>{{ scope.row.amount / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="代付天数" prop="days"></el-table-column>

      <!-- <el-table-column label="状态" prop="status">
        <template #default="scope">
          <eb-badge
            :list="this.const.STATUS"
            :target="scope.row.status"
          ></eb-badge>
        </template>
      </el-table-column> -->

      <el-table-column label="备注" prop="remark"></el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <span
            class="option option-primary"
            @click="showEdit(scope.row)"
            v-auth="this.per.AGENT_EMPLOYEE_UPDATA"
          >
            修改
          </span>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>

    <EditModal
      :modalData="modalData"
      :modalType="modalType"
      :ref="MODAL_KEY.EDIT_MODAL"
      @submit="submit"
    ></EditModal>
  </div>
</template>
    
    <script>
import { fetchAgentList, addAgent, updateAgent } from "@/api/zzAgent";
import EditModal from "./component/EditModal";
export default {
  components: { EditModal },
  data() {
    return {
      dataSource: [],
      query: {},
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      modalData: {},
      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
      },
      total: 0,
    };
  },
  methods: {
    // 删除用户
      doDel(e) {
        this.$confirm("是否要删除用户", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then((res) => {
          delAdmin({ id: e.id }).then((res) => {
            this.getList();
            this.$message.success("删除成功");
          });
        });
      },

    showAdd() {
      this.modalData = {};
      this.modalType = "add";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },
    
    showEdit(data) {
      this.modalData = this.deepClone(data);
      this.modalType = "edit";
      this.modalData.amount = this.modalData.amount / 100;
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },

    submit(data) {
      let request = updateAgent;
      let msg = "编辑代理成功";
      if (this.validatenull(data.id)) {
        request = addAgent;
        msg = "添加代理成功";
        data.roleType = this.const.ROLE_TYPE_CODE.AGENT_MANAGE;
        data.stationId = 0;
      }

      request(data).then((res) => {
        this.$message.success(msg);
        this.$refs[this.MODAL_KEY.EDIT_MODAL].closeModal();
        this.getList();
      });
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchAgentList({ ...this.query, ...this.pages }).then((res) => {
        this.dataSource = res.data.data.records || [];
        this.total = res.data.data.total;
      });
    },
  },

  mounted() {
    this.getList();
  },
};
</script>
    