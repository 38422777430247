<template>
  <el-dialog
    :title="modalType === 'edit' ? '编辑账户' : '新增账户'"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="站长名称：" prop="name">
        <el-input
          v-model="modalData.name"
          placeholder="请输入站长名称"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="用户名：" prop="userName" v-if="this.modalType == 'add'">
        <el-input
          v-model="modalData.userName"
          placeholder="请输入用户名"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item
        v-if="this.modalType == 'add'"
        label="密码："
        prop="password"
        :rules="[
          {
            required: this.modalType === 'add',
            message: '请输入密码',
            trigger: 'change',
          },
        ]"
      >
        <el-input
          type="password"
          v-model="modalData.password"
          placeholder="请输入密码"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="手机号码：" prop="phone">
        <el-input
          v-model="modalData.phone"
          placeholder="请输入手机号码"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="押金：" prop="deposited">
        <el-input
          v-model="modalData.deposited"
          placeholder="请输入押金金额"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="代付名额：" prop="userNum">
        <el-input
          v-model="modalData.userNum"
          placeholder="请输入代付名额"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="代付天数：" prop="days">
        <el-input
          v-model="modalData.days"
          placeholder="请输入代付一次多少天"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="代付套餐结算金额：" prop="amount">
        <el-input
          v-model="modalData.amount"
          placeholder="请输入代付结算金额"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="备注：" prop="remark">
        <el-input
          v-model="modalData.remark"
          placeholder="请输入备注"
          :maxLength="50"
        ></el-input>
      </el-form-item>

    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>

      <el-button @click="submitAgent" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { validatorForm } from "@/common/util";
export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData", "modalType", "agentList"],
  data() {
    return {
      formName: "form",
      ruleValidate: {
        password: [
          {
            required: true,
            message: "请输入密码不能为空",
            trigger: "change",
          },
        ],
        userName: [
          {
            required: true,
            message: "请输入用户名不能为空",
            trigger: "change",
          },
        ],

        userNum: [
          {
            required: true,
            message: "请输入代付用户数量不能为空",
            trigger: "change",
          },
        ],

        deposited: [
          {
            required: true,
            message: "请输入押金不能为空",
            trigger: "change",
          },
        ],

        name: [
          {
            required: true,
            message: "请输入昵称不能为空",
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "change",
          },
          { validator: validatorForm.phone },
        ],
      },
    };
  },
  methods: {

    submitAgent() {
      
      this.validateForm().then((res) => {
        console.log("submit22");
        let obj = this.deepClone(this.modalData);
        delete obj.createTime;
        obj.amount = obj.amount * 100;
        this.$emit("submit", obj);
      });
    },
  },
};
</script>
